import { Resize, TransformerOption } from '@cld-apis/types';
import Image, { ImageProps } from 'next/legacy/image';
import React from 'react';
import { buildUrlCloudinary } from '@/lib/cloudinary/buildUrlCloudinary';
import { loaderCloudinary } from '@/lib/cloudinary/loaderCloudinary';
import styles from './CloudinaryImage.module.scss';

interface Props {
  ['data-test-id']?: string;
  alt: string;
  className?: string;
  cloudinaryResize?: Omit<Resize, 'width'>;
  isAbovetheFold?: boolean;
  placeholderPriority?: boolean;
  placeholderWidth?: number;
  src: string;
  transformationOptions?: (width: number) => TransformerOption;
}

export type CloudinaryImageProps = Omit<ImageProps, 'src'> & Props;

export const CloudinaryImage = ({
  placeholderPriority,
  src,
  alt,
  placeholderWidth = 100,
  cloudinaryResize,
  isAbovetheFold = false,
  transformationOptions,
  'data-test-id': dataTestId,
  ...rest
}: CloudinaryImageProps) => {
  const placeholder = buildUrlCloudinary({
    src,
    transformations: {
      effect: { name: 'blur', value: 200 },
      quality: 30,
      resize: {
        type: cloudinaryResize?.type || 'fit',
        width: placeholderWidth,
        aspectRatio: cloudinaryResize?.aspectRatio,
      },
    },
  });

  const buildTransformations = (width: number): TransformerOption =>
    !!transformationOptions
      ? transformationOptions(width)
      : {
          resize: {
            ...cloudinaryResize,
            type: cloudinaryResize?.type || 'scale',
            width: isAbovetheFold && width <= 828 ? 1080 : width,
          },
        };
  return (
    <>
      <Image
        {...rest}
        priority={placeholderPriority}
        alt="Image Placeholder"
        unoptimized
        src={placeholder}
        className={styles.placeholderImage}
      />
      <Image
        {...rest}
        src={src}
        alt={alt}
        data-test-id={dataTestId}
        loader={options =>
          loaderCloudinary(options, {
            ...buildTransformations(options.width),
          })
        }
      />
    </>
  );
};

// Currently there is no option to aplly blur_region to image with specific coordinations.

// There is a work around to place this on the loader
// loaderCloudinary(options, {
//     ...buildTransformations(options.width),
// })
//     .replace('e_bar', 'e_blur_region:2000,h_1500,w_546,x_2150,y_0')
//     .replace('e_foo', 'e_blur_region:100,h_1500,w_1500,x_0,y_0');

// And also create a chained transformations like this on the buildTransformations function
// chaining: [{ effect: { name: 'bar' } }, { effect: { name: 'foo' } }],
