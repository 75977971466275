import React from 'react';
import { IconAt } from '@/components/Icons/IconAt';
import { IconBe } from '@/components/Icons/IconBe';
import { IconDe } from '@/components/Icons/IconDe';
import { IconDk } from '@/components/Icons/IconDk';
import { IconEsSmall } from '@/components/Icons/IconEsSmall';
import { IconFr } from '@/components/Icons/IconFr';
import { IconGb } from '@/components/Icons/IconGb';
import { IconNl } from '@/components/Icons/IconNl';
import { countries, Countries, CountriesData } from '@/types/Countries';
import { languages } from '@/types/Languages';

export const countriesData: CountriesData = {
  [countries.BE]: {
    preview: 'Belgium',
    label: 'België/Belgique',
    languages: [languages.nl, languages.fr, languages.en],
    icon: <IconBe />,
  },
  [countries.DE]: {
    preview: 'Germany',
    label: 'Deutschland',
    languages: [languages.de, languages.en],
    icon: <IconDe />,
    hasSpecialOffer: true,
  },
  [countries.DK]: {
    preview: 'Denmark',
    label: 'Danmark',
    languages: [languages.da, languages.en],
    icon: <IconDk />,
  },
  [countries.FR]: {
    preview: 'France',
    label: 'France',
    languages: [languages.fr, languages.en],
    icon: <IconFr />,
  },
  [countries.GB]: {
    preview: 'United Kingdom',
    label: 'United Kingdom',
    languages: [languages.en],
    icon: <IconGb />,
    hasSpecialOffer: true,
  },
  [countries.NL]: {
    preview: 'The Netherlands',
    label: 'Nederland',
    languages: [languages.nl, languages.en],
    icon: <IconNl />,
    hasSpecialOffer: true,
  },
  [countries.AT]: {
    preview: 'Austria',
    label: 'Österreich',
    languages: [languages.de, languages.en],
    icon: <IconAt />,
  },
  [countries.ES]: {
    preview: 'Spain',
    label: 'España',
    languages: [languages.es, languages.en],
    icon: <IconEsSmall />,
  },
};

type LocalesType = {
  [key in Countries]: {
    [key: string]: string;
  };
};

(function ValidateCountries() {
  const locales: LocalesType = require('../../config/config.locales.json');
  Object.keys(locales).forEach(key => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!Object.values(countries).includes(key as Countries)) {
      console.warn(
        'Following Country code is missing from the @/data/countries config',
        key
      );
    }
  });
})();
